import Image from 'next/image'
import React from 'react'
import { Absolute } from '../bases/Absolute'
import { Center } from '../bases/Center'
import { VStack } from '../bases/VStack'
import GaugeCover from '@/public/images/gaugeCover.png'
import GaugeColor from '@/public/images/gaugeColor.png'
import GaugeTop from '@/public/images/gaugeTop.png'
import MegaSpark from '@/public/images/mega_spark.png'
import MegaSparkEffect from '@/public/images/mega_spark_effect.png'
import ExtractedTop from '@/public/images/after_extract_top.png'
import ExtractedBase from '@/public/images/after_extract_base.png'
import ExtractedBottom from '@/public/images/after_extract_btm.png'

export const EnergyGauge = (props: {
  energy?: number
  maxEnergy: number
  extractedEnergy: number
  height: number
  feverSparkRemain?: number | null
  className?: string
}) => {
  // エネルギー量
  const width = props.height * (210 / 816)
  const energy = props.energy ?? 0
  const gaugeHeight = props.maxEnergy > 0 && energy > 0 ? (props.height * energy) / props.maxEnergy : 0

  // MegaSpark
  const isMegaSpark = props.maxEnergy > 0 && energy > 0 && energy === props.maxEnergy
  const megaSparkHeight = props.height + 10
  const megaSparkWidth = megaSparkHeight * (160 / 300)

  // MegaSparking
  const isMegaSparking = props.feverSparkRemain !== null && props.feverSparkRemain !== 0

  // Extract
  const isExtract = props.extractedEnergy > 0
  const extractedEnergy = props.extractedEnergy
  const extractedHeight = isExtract ? (props.height * extractedEnergy) / props.maxEnergy : 0
  const remainingEnergy = energy - extractedEnergy
  const extractedTopHeight = width * (10 / 70)

  const displayNormalGauge = energy > 0 && remainingEnergy !== 0 && (!isMegaSparking || remainingEnergy !== 0)

  return (
    <VStack
      className={`items-center ${props.feverSparkRemain === 0 && remainingEnergy === 0 ? 'grayscale' : ''} ${
        props.className
      }`}
    >
      <Center
        className="relative overflow-hidden"
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomLeftRadius: 11,
          borderBottomRightRadius: 11,
        }}
      >
        <Absolute className="bottom-0 items-end overflow-hidden" style={{ height: gaugeHeight }}>
          {displayNormalGauge && <Image height={props.height} width={width} src={GaugeColor} alt="" />}
        </Absolute>
        {displayNormalGauge && !isMegaSpark ? (
          <Absolute style={{ bottom: gaugeHeight - 0.8 * width }}>
            <Image height={width} width={width} src={GaugeTop} alt="" />
          </Absolute>
        ) : null}
        {isExtract && (
          <>
            <Absolute className="-bottom-1 items-end overflow-hidden" style={{ height: gaugeHeight }}>
              <VStack style={{ height: extractedHeight }}>
                <Image src={ExtractedTop} width={width} height={extractedTopHeight} alt="" />
                <Image
                  height={extractedHeight - extractedTopHeight * 2}
                  className="h-full w-full"
                  width={width}
                  src={ExtractedBase}
                  alt=""
                />
                <Image src={ExtractedBottom} width={width} alt="" />
              </VStack>
            </Absolute>
          </>
        )}
        {isMegaSpark && (
          <Absolute
            className="-bottom-1 items-end overflow-hidden"
            style={{ height: megaSparkHeight, width: megaSparkWidth }}
          >
            <Image height={megaSparkHeight} width={megaSparkWidth} src={MegaSpark} alt="" />
          </Absolute>
        )}
        <Image height={props.height} width={width} src={GaugeCover} alt="" />
      </Center>
      {isMegaSpark && props.feverSparkRemain !== 0 && (
        <Absolute
          className="-top-1 items-end overflow-hidden"
          style={{ height: megaSparkHeight, width: megaSparkWidth }}
        >
          <Image height={megaSparkHeight} width={megaSparkWidth} src={MegaSparkEffect} alt="" />
        </Absolute>
      )}
    </VStack>
  )
}
