import { HStack } from '../bases/HStack'
import { Text } from '../bases/Text'

// MegaSparkingの回数分母
export const FEVER_SPARK_REMAIN_DENOMINATOR = 30

type Size = 'M' | 'L'

export const MegaSparkingLabel = (props: { feverSparkRemain: number; className?: string; size: Size }) => {
  const textSize = {
    M: { numerator: 'text-base', denominator: 'text-xs' },
    L: { numerator: 'text-[28px]', denominator: 'text-lg' },
  }[props.size]
  return (
    <HStack className={`w-full justify-center gap-x-1.5 ${props.className}`}>
      <HStack className="items-end justify-center rounded-md bg-black/40 px-3 backdrop-blur-[2.8px]">
        <Text
          className={`font-bold ${
            props.feverSparkRemain > 0 ? 'text-akiverse-yellow' : 'text-[#FF0000]/90'
          } ${textSize.numerator}`}
        >
          {props.feverSparkRemain}
        </Text>
        <Text className={`mb-0.5 font-bold text-white ${textSize.denominator}`}>
          /{FEVER_SPARK_REMAIN_DENOMINATOR}
        </Text>
      </HStack>
    </HStack>
  )
}
